<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить акцию</h4>
          </div>
        </md-card-header>

        <ValidationObserver ref="addPromotionForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <div class="md-layout-item md-large-size-100">
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Название</label>
                    <md-input v-model="title" @focus="reset" />
                  </md-field>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Описание</label>
                    <md-textarea v-model="description" @focus="reset" />
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-large-size-100">
                <h5 class="md-subheading" style="margin-bottom: 15px">
                  Добавить изображение
                </h5>
                <VueUploadMultipleImage
                  :key="`uploader_${uploaderKey}`"
                  drag-text="Drag here"
                  browse-text="Browse"
                  drop-text="Drop here"
                  accept="image/jpeg, image/png, image/jpg"
                  :max-image="1"
                  :show-edit="false"
                  :show-primary="false"
                  @before-remove="beforeRemove"
                  @upload-success="imagesChange"
                />
              </div>

              <div class="md-layout-item md-large-size-20">
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <label>Процент скидки</label>
                    <md-input
                      v-model="percent"
                      type="number"
                      :min="1"
                      :max="100"
                      @focus="reset"
                      @change="onPercentChange"
                    />
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-large-size-20">
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-datepicker
                    v-model="fromDate"
                    :md-model-type="String"
                    :class="{ error: errors.length }"
                    @md-opened="reset"
                  >
                    <ValidationError :errors="errors" />
                    <label>Дата начала</label>
                  </md-datepicker>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-large-size-20">
                <ValidationProvider
                  v-slot="{ errors, reset }"
                  mode="passive"
                  :slim="true"
                  rules="required"
                >
                  <md-datepicker
                    v-model="toDate"
                    :md-model-type="String"
                    :class="{ error: errors.length }"
                    @md-opened="reset"
                  >
                    <ValidationError :errors="errors" />
                    <label>Дата окончания</label>
                  </md-datepicker>
                </ValidationProvider>
              </div>

              <md-card style="z-index: 10">
                <md-card-header
                  data-background-color="bluegrey"
                  class="card-header"
                >
                  <div class="card-header-info">
                    <h4 class="title">Список товаров</h4>
                    <div class="search-wrap md-layout">
                      <md-field
                        class="table-input md-layout-item md-large-size-10"
                        :md-clearable="true"
                        @md-clear="clearHandler('id')"
                      >
                        <label>Номер товара</label>
                        <md-input
                          v-model="searchId"
                          @keyup.enter="searchHandler"
                        />
                      </md-field>

                      <md-field
                        class="table-input md-layout-item md-large-size-20"
                        :md-clearable="true"
                        @md-clear="clearHandler('name')"
                      >
                        <label>Наименование</label>
                        <md-input
                          v-model="searchName"
                          @keyup.enter="searchHandler"
                        />
                      </md-field>

                      <div
                        class="
                          treeselect-wrapper
                          table-input
                          md-layout-item md-large-size-20
                        "
                      >
                        <Treeselect
                          v-model="searchCategory"
                          :options="categories"
                          :before-clear-all="
                            () => {
                              clearHandler('category');
                            }
                          "
                          value-format="object"
                          value-consists-of="LEAF_PRIORITY"
                          placeholder="Категория"
                          @select="
                            (node) => {
                              node && searchHandler(true, node.label);
                            }
                          "
                        />
                      </div>

                      <md-field
                        class="table-input md-layout-item md-large-size-20"
                        :md-clearable="true"
                        @md-clear="clearHandler('brand')"
                      >
                        <label>Бренд</label>
                        <md-select
                          v-model="searchBrand"
                          @md-selected="searchHandler"
                        >
                          <md-option
                            v-for="item in brands"
                            :key="item.id"
                            :value="item.name"
                            placeholder="Бренд"
                          >
                            {{ item.name }}
                          </md-option>
                        </md-select>
                      </md-field>

                      <md-button
                        class="
                          md-raised md-primary md-layout-item md-large-size-10
                        "
                        @click="searchHandler"
                      >
                        Поиск
                      </md-button>
                    </div>
                  </div>
                </md-card-header>
                <md-card-content>
                  <ProductsTableWithSelect
                    :selected-items="products"
                    @sort="sortProducts"
                    @prev="goToPage('prev')"
                    @next="goToPage('next')"
                    @select-items="products = $event"
                  />
                </md-card-content>
              </md-card>
            </div>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewPromotion">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import ProductsTableWithSelect from "@/components/Tables/ProductsTableWithSelect";

import { mapState, mapGetters, mapActions } from "vuex";

import VueUploadMultipleImage from "vue-upload-multiple-image";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    ProductsTableWithSelect,
    Treeselect,
    VueUploadMultipleImage,
  },

  data() {
    return {
      uploaderKey: 0,
      title: null,
      description: null,
      image: null,
      percent: null,
      fromDate: null,
      toDate: null,
      products: [],
      searchId: null,
      searchName: null,
      searchCategory: null,
      searchBrand: null,
      sortBy: "id",
      sortOrder: "asc",
      currentPage: 1,
    };
  },

  computed: {
    ...mapState({
      brands: (state) => state.additionalData.brands,
    }),

    ...mapGetters({ categories: "additionalData/reworkedCategories" }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.searchHandler();
    await this.getAdditionalData();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),
    ...mapActions("products", ["searchProducts"]),
    ...mapActions("promotions", ["createPromotion"]),

    async addNewPromotion() {
      const isValid = await this.$refs.addPromotionForm.validate();
      if (!isValid) {
        return;
      }

      let promotion = new FormData();
      promotion.append("title", this.title);
      promotion.append("description", this.description);

      if (this.image) {
        promotion.append("image", this.image);
      }

      promotion.append("percent", this.percent);
      promotion.append("from_date", this.fromDate);
      promotion.append("to_date", this.toDate);

      for (let [index, product] of this.products.entries()) {
        promotion.append(`products_ids[${index}]`, product);
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createPromotion(promotion);
      if (success) {
        this.initPromotion();
        await this.searchHandler();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    beforeRemove(_, done) {
      this.image = null;
      done();
    },

    clearHandler(fieldName) {
      switch (fieldName) {
        case "id":
          this.searchId = null;
          break;
        case "name":
          this.searchName = null;
          break;
        case "category":
          this.searchCategory = null;
          break;
        case "brand":
          this.searchBrand = null;
          break;
        default:
          break;
      }

      this.searchHandler();
    },

    goToPage(direction) {
      direction == "prev" ? this.currentPage-- : this.currentPage++;
      this.searchHandler(false);
    },

    imagesChange(formData) {
      this.image = formData.get("file");
    },

    initPromotion() {
      this.$refs.addPromotionForm.reset();
      this.title = null;
      this.description = null;
      this.image = null;
      this.percent = null;
      this.fromDate = null;
      this.toDate = null;
      this.products = [];
      this.searchId = null;
      this.searchName = null;
      this.searchCategory = null;
      this.searchBrand = null;
      this.uploaderKey++;
    },

    onPercentChange(event) {
      if (event.target.value <= 0) {
        this.percent = 1;
      } else if (event.target.value > 100) {
        this.percent = 100;
      }
    },

    async searchHandler(toFirstPage = true, category) {
      this.$store.commit("SET_SHOW_LOADER", true);

      if (toFirstPage) {
        this.currentPage = 1;
      }

      await this.searchProducts({
        id: this.searchId,
        name: this.searchName,
        category: category || this.searchCategory?.label,
        brand: this.searchBrand,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
      });

      this.$store.commit("SET_SHOW_LOADER", false);
    },

    sortProducts(params) {
      this.sortBy = params.sortBy;
      this.sortOrder = params.sortOrder;
      this.searchHandler(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}

.search-wrap {
  display: flex;
  justify-content: space-between;

  & > *:not(:last-child) {
    margin-right: 8px;
  }

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  .md-button {
    width: 20%;
    height: max-content;
    flex-shrink: 0;
    align-self: center;
  }

  .md-field {
    .md-input,
    label {
      color: white !important;
      -webkit-text-fill-color: white !important;
    }

    &::v-deep {
      .md-input {
        color: white !important;
        -webkit-text-fill-color: white !important;
      }

      .md-clear {
        background: transparent !important;
        box-shadow: none;
        top: 18px;

        i > svg {
          fill: white !important;
        }
      }
    }
  }

  .treeselect-wrapper {
    margin: 4px 8px 24px;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid white;
    padding: 0;

    &::v-deep {
      .vue-treeselect {
        margin: 0 -10px;

        &__control {
          background: transparent;
          border: none;
        }

        &__placeholder,
        &__single-value {
          color: white;
        }

        &__label {
          color: black;
        }
      }
    }
  }
}
</style>
