var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Добавить акцию")])])]),_c('ValidationObserver',{ref:"addPromotionForm",attrs:{"slim":true}},[_c('md-card-content',[_c('div',{staticClass:"md-layout md-gutter"},[_c('div',{staticClass:"md-layout-item md-large-size-100"},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Описание")]),_c('md-textarea',{on:{"focus":reset},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-large-size-100"},[_c('h5',{staticClass:"md-subheading",staticStyle:{"margin-bottom":"15px"}},[_vm._v(" Добавить изображение ")]),_c('VueUploadMultipleImage',{key:("uploader_" + _vm.uploaderKey),attrs:{"drag-text":"Drag here","browse-text":"Browse","drop-text":"Drop here","accept":"image/jpeg, image/png, image/jpg","max-image":1,"show-edit":false,"show-primary":false},on:{"before-remove":_vm.beforeRemove,"upload-success":_vm.imagesChange}})],1),_c('div',{staticClass:"md-layout-item md-large-size-20"},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Процент скидки")]),_c('md-input',{attrs:{"type":"number","min":1,"max":100},on:{"focus":reset,"change":_vm.onPercentChange},model:{value:(_vm.percent),callback:function ($$v) {_vm.percent=$$v},expression:"percent"}})],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-large-size-20"},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-datepicker',{class:{ error: errors.length },attrs:{"md-model-type":String},on:{"md-opened":reset},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Дата начала")])],1)]}}])})],1),_c('div',{staticClass:"md-layout-item md-large-size-20"},[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-datepicker',{class:{ error: errors.length },attrs:{"md-model-type":String},on:{"md-opened":reset},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Дата окончания")])],1)]}}])})],1),_c('md-card',{staticStyle:{"z-index":"10"}},[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"bluegrey"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Список товаров")]),_c('div',{staticClass:"search-wrap md-layout"},[_c('md-field',{staticClass:"table-input md-layout-item md-large-size-10",attrs:{"md-clearable":true},on:{"md-clear":function($event){return _vm.clearHandler('id')}}},[_c('label',[_vm._v("Номер товара")]),_c('md-input',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchHandler.apply(null, arguments)}},model:{value:(_vm.searchId),callback:function ($$v) {_vm.searchId=$$v},expression:"searchId"}})],1),_c('md-field',{staticClass:"table-input md-layout-item md-large-size-20",attrs:{"md-clearable":true},on:{"md-clear":function($event){return _vm.clearHandler('name')}}},[_c('label',[_vm._v("Наименование")]),_c('md-input',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchHandler.apply(null, arguments)}},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1),_c('div',{staticClass:"\n                        treeselect-wrapper\n                        table-input\n                        md-layout-item md-large-size-20\n                      "},[_c('Treeselect',{attrs:{"options":_vm.categories,"before-clear-all":function () {
                            _vm.clearHandler('category');
                          },"value-format":"object","value-consists-of":"LEAF_PRIORITY","placeholder":"Категория"},on:{"select":function (node) {
                            node && _vm.searchHandler(true, node.label);
                          }},model:{value:(_vm.searchCategory),callback:function ($$v) {_vm.searchCategory=$$v},expression:"searchCategory"}})],1),_c('md-field',{staticClass:"table-input md-layout-item md-large-size-20",attrs:{"md-clearable":true},on:{"md-clear":function($event){return _vm.clearHandler('brand')}}},[_c('label',[_vm._v("Бренд")]),_c('md-select',{on:{"md-selected":_vm.searchHandler},model:{value:(_vm.searchBrand),callback:function ($$v) {_vm.searchBrand=$$v},expression:"searchBrand"}},_vm._l((_vm.brands),function(item){return _c('md-option',{key:item.id,attrs:{"value":item.name,"placeholder":"Бренд"}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('md-button',{staticClass:"\n                        md-raised md-primary md-layout-item md-large-size-10\n                      ",on:{"click":_vm.searchHandler}},[_vm._v(" Поиск ")])],1)])]),_c('md-card-content',[_c('ProductsTableWithSelect',{attrs:{"selected-items":_vm.products},on:{"sort":_vm.sortProducts,"prev":function($event){return _vm.goToPage('prev')},"next":function($event){return _vm.goToPage('next')},"select-items":function($event){_vm.products = $event}}})],1)],1)],1),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewPromotion}},[_vm._v(" Сохранить ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }